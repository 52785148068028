import gsap from 'gsap';

export const slideOn = (elem1) => {
  gsap.from([elem1], {
    duration: 1,
    y: 300,
    opacity: 0,
    ease: 'power4.inOut',
  });
};

//Open menu
export const menuShow = (elem1, elem2) => {
  gsap.from([elem1, elem2], {
    duration: 0.7,
    height: 0,
    transformOrigin: 'right top',
    skewY: 2,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.2,
    },
  });
};

//Close menu
export const menuHide = (elem1, elem2) => {
  gsap.to([elem1, elem2], {
    duration: 0.8,
    height: 0,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.07,
    },
  });
};
//Stagger links
export const staggerLinks = (LiRefs) => {
  gsap.from(LiRefs, {
    duration: 0.8,
    y: 100,
    delay: 0.1,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.4,
    },
  });
};

export const unstaggerCats = (LiRefs) => {
  gsap.to('#worklist', { opacity: 0, y: 110, duration: 0.4 });
  let even = [];
  let odd = [];
  LiRefs.forEach((idx, i) => {
    if (i % 2 === 0) {
      even.push(idx);
    } else {
      odd.push(idx);
    }
  });
  gsap.to(even, {
    duration: 0.7,
    x: -200,
    opacity: 0,
    delay: 0.05,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.2,
    },
  });

  gsap.to(odd, {
    duration: 0.5,
    x: 200,

    opacity: 0,
    delay: 0.07,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.2,
    },
  });
};

export const staggerCats = (LiRefs) => {
  let even = [];
  let odd = [];
  LiRefs.forEach((idx, i) => {
    if (i % 2 === 0) {
      even.push(idx);
    } else {
      odd.push(idx);
    }
  });
  gsap.from(even, {
    duration: 0.5,
    x: -200,
    opacity: 0,
    delay: 0.05,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.2,
    },
  });

  gsap.from(odd, {
    duration: 0.5,
    x: 200,

    opacity: 0,
    delay: 0.05,
    ease: 'power4.inOut',
    stagger: {
      amount: 0.2,
    },
  });
};
export const ColophonOpen = (clf) => {
  gsap.from(clf, {
    x: '-110%',
    y: 0,
  });
  gsap.to(clf, {
    duration: 0.7,
    x: 0,
    y: 0,
    ease: 'power4.inOut',
    transformOrigin: 'left bottom',
  });
};

export const ColophonClose = (clf) => {
  gsap.from(clf, {
    x: 0,
    y: 0,
  });
  gsap.to(clf, {
    duration: 0.7,
    x: 0,
    y: 100,
    opacity: 0,
    ease: 'power4.inOut',
  });
};
