import { Link } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';
import Menu from './Menu';
import { StaticImage, getImage } from 'gatsby-plugin-image';
// import Image from 'gatsby-image';
// import logo from '../images/popland_trans.png';
import { globalHistory } from '@reach/router';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
//import { getFluidImageObject } from 'gatsby-transformer-cloudinary/api';
gsap.registerPlugin(ScrollTrigger);

const Header = (menu) => {
  const [fluid, setFluid] = useState(undefined);

  // useEffect(() => {
  //   getFluidImageObject({
  //     public_id: 'popland_trans',
  //     cloudName: 'popland',
  //     originalHeight: 98,
  //     originalWidth: 302,
  //     maxWidth: 302,
  //     transformations: ['c_fill', 'f_auto'],
  //   }).then((result) => setFluid(result));
  // }, []);

  //console.log(fluid);

  let scroller = useRef(null);
  //MENU LABEL STATE
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: 'Menu',
  });
  // BUTTON STATE
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // gsap.fromTo(
    //   '#myheader',
    //   { autoAlpha: 0 },
    //   { autoAlpha: 1, delay: 0.3, duration: 0.3 }
    // );

    gsap.fromTo(
      scroller,
      { autoAlpha: 0, y: 2 },
      {
        duration: 0.5,
        autoAlpha: 1,
        y: 12,

        scrollTrigger: {
          trigger: scroller,
          start: '100',
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      setState({ clicked: false, menuName: 'Menu' });
    });
  }, [setState]);

  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: 'Chiudi',
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: 'Menu',
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: 'Chiudi',
      });
    }
  };

  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };
  return (
    <>
      <header id="myheader">
        <div className="flex justify-between z-20 relative  px-2 md:px-0">
          <div>
            <Link to="/">
              {/* <img src={logo} alt="Logo" /> */}
              <StaticImage src="../images/popland_trans.png" alt="popland" />
              {/* {fluid ? (
                <Image fluid={fluid} style={{ width: '260px' }} />
              ) : (
                <div>Loading</div>
              )} */}
            </Link>
          </div>
          <div>
            <button
              disabled={disabled}
              className="uppercase"
              onClick={handleMenu}
            >
              {state.menuName}
            </button>
          </div>
        </div>
        <Menu state={state} menu={menu} setState={setState} />
      </header>
      <div ref={(el) => (scroller = el)} className="fixed top-0 container z-20">
        <div className="flex container mx-auto justify-center relative w-full">
          <button
            disabled={disabled}
            onClick={handleMenu}
            className="border border-gray-300 p-1 rounded-full h-16 w-16 bg-gray-200 uppercase"
          >
            {state.menuName}
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
