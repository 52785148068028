import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ColophonOpen, ColophonClose } from './Animations';

const Colophon = () => {
  let clf = useRef(null);
  let cWrapper = useRef(null);

  //MENU LABEL STATE
  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: 'Colophon',
    closeName: 'Chiudi',
  });

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    //console.log('changed', state.clicked);
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      ColophonClose(clf);
      // Set menu to display none
      gsap.to(cWrapper, { duration: 1, css: { display: 'none' } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(cWrapper, { duration: 0, css: { display: 'block' } });
      //Allow menu to have height of 100%
      gsap.to(clf, {
        duration: 0,
        opacity: 1,
        y: 0,
        height: '100%',
      });
      ColophonOpen(clf);
      //   textIntro(info);
    }
  }, [state]);

  const handleColophon = () => {
    disableCLF();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: 'Colophon',
        closeName: 'Chiudi',
      });
    } else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: 'Colophon',
        closeName: '',
      });
    } else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: 'Colophon',
        closeName: 'Chiudi',
      });
    }
  };

  const disableCLF = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1200);
  };

  return (
    <>
      <div className="fixed bottom-0 left-0 mb-2 ml-2 text-sm uppercase">
        <div className="flex">
          <div className="relative">
            <button
              className=" font-serif italic"
              onClick={handleColophon}
              disabled={disabled}
            >
              {state.menuName}
            </button>
          </div>
        </div>
      </div>

      <div
        className="fixed bottom-0 left-0 w-2/3 h-18 hidden"
        ref={(el) => (cWrapper = el)}
      >
        <div
          className="relative  text-sm   bg-gray-200 h-full  my-2 overflow-hidden rounded-tr-lg"
          ref={(el) => (clf = el)}
        >
          <div className="flex p-5">
            <div className="relative">
              popLand - &copy; 2021
              <br />
              <span className="italic">
                popLand di Lorenzo Noccioli - P. IVA 06844720489 / Tel.
                3453397052 / Email pop@popland.it
              </span>
              <br />
              made with Gatsby/React
            </div>
            <div className="p-5 ">
              <button onClick={handleColophon}>X CLOSE</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Colophon;
