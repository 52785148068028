import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const SeoBasic = ({ slug }) => {
  const seores = useStaticQuery(graphql`
    query SeoQuery {
      allSeoYaml {
        edges {
          node {
            slug
            seo {
              canonical
              title
              metaDesc
              metaRobotsNofollow
              metaRobotsNoindex
              opengraphDescription
              opengraphModifiedTime
              opengraphSiteName
              opengraphTitle
              opengraphType
              opengraphUrl
            }
          }
        }
      }
    }
  `);

  const result = seores.allSeoYaml.edges.filter((x) => x.node.slug === slug);
  const data = result[0].node.seo;

  return (
    <Helmet>
      <title>{data.title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={data.metaDesc} />
      <meta
        name="robots"
        content={`${data.metaRobotsNofollow} ${data.metaRobotsNoIndex} follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />
      <link rel="canonical" href={data.canonical} />
      <meta property="og:locale" content="it_IT" />
      <meta property="og:type" content={data.opengraphType} />
      <meta property="og:title" content={data.opengraphType} />
      <meta property="og:description" content={data.opengraphDescription} />
      <meta property="og:url" content={data.opengraphUrl} />
      <meta property="og:site_name" content={data.opengraphSiteName} />
      <meta
        property="article:modified_time"
        content={data.opengraphModifiedTime}
      />
    </Helmet>
  );
};

export default SeoBasic;
