import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { menuShow, menuHide, staggerLinks } from './Animations';
import { useStaticQuery, graphql } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';

const Menu = ({ state, setState }) => {
  const query = useStaticQuery(graphql`
    query {
      allMenuYaml {
        nodes {
          menuItems {
            label
            url
          }
        }
      }
    }
  `);
  const menu = query.allMenuYaml.nodes[0].menuItems;
  //console.log(menu);

  let menuWrapper = useRef(null);
  let LiRefs = useRef([]);
  let LinkRefs = useRef([]);
  let show1 = useRef(null);
  let show2 = useRef(null);

  useEffect(() => {
    // If the menu is open and we click the menu button to close it.
    if (state.clicked === false) {
      // If menu is closed and we want to open it.

      menuHide(show2, show1);
      // Set menu to display none
      gsap.to(menuWrapper, { duration: 1, css: { display: 'none' } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      // Set menu to display block
      gsap.to(menuWrapper, { duration: 0, css: { display: 'block' } });
      //Allow menu to have height of 100%
      gsap.to([show1, show2], {
        duration: 0,
        opacity: 1,
        height: '100%',
      });
      menuShow(show1, show2);
      //   textIntro(info);

      staggerLinks(LiRefs.current);
    }
  }, [state]);

  const handleMenu = (event) => {
    // event.preventDefault();
    //const link = event.target.getAttribute('href');

    setState({
      clicked: !state.clicked,
      menuName: 'Menu',
    });

    // setTimeout(() => {
    //   navigate(link);
    // }, 800);
  };

  // function animal(exit, node, e) {
  //   // console.log('animal', e);
  //   // gsap.to('.panel', { delay: 0.1, duration: 0.8, opacity: 0,ease: 'power4.inOut', });
  //   gsap.to('.panel', {
  //     delay: 0.1,
  //     duration: 0.8,
  //     x: '120%',
  //     ease: 'power4.inOut',
  //   });
  //   gsap.to('.contatti', { delay: 0.1, duration: 0.8, opacity: 0 });
  // }
  // function animal2(entry, node, e) {
  //   // console.log('animal2', e);
  //   gsap.fromTo(
  //     '.contatti',
  //     { x: '-100%' },
  //     { delay: 0, duration: 0.8, x: 0, ease: 'back' }
  //   );
  //   gsap.fromTo(
  //     '.panel',
  //     { opacity: 0 },
  //     { delay: 0.1, duration: 0.8, opacity: 1 }
  //   );
  // }

  return (
    <div
      ref={(el) => (menuWrapper = el)}
      className="fixed top-0 right-0 left-0 w-full h-full hidden z-10"
    >
      <div
        ref={(el) => (show1 = el)}
        className="bg-gray-300 fixed top-0 right-0 left-0 w-full h-full "
      ></div>
      <div
        ref={(el) => (show2 = el)}
        className="bg-gray-200 relative h-full overflow-hidden"
      >
        <div>
          <div className="relative">
            <div className="flex justify-between relative items-center text-center top-200 w-max mx-auto">
              <nav className="block">
                <ul className="m-0 p-0">
                  {menu.map((D, i) => {
                    return (
                      <li
                        key={i}
                        ref={(el) => (LiRefs.current[i + 1] = el)}
                        className="list-none text-4xl md:text-6xl lg:text-8xl font-bold relative overflow-hidden uppercase pb-5"
                      >
                        <TransitionLink
                          ref={(el) => (LinkRefs.current[i + 1] = el)}
                          to={D.url}
                          exit={{
                            length: 1,
                            delay: 0.9,
                            // trigger: ({ exit, node, e }) => animal(exit, node),
                          }}
                          entry={{
                            delay: 0.9,
                            // trigger: ({ entry, node, e }) =>
                            // animal2(entry, node),
                          }}
                          className="hover:text-gray-600"
                          activeClassName="border-b-2 border-gray-600"
                          onClick={handleMenu}
                        >
                          {D.label}
                        </TransitionLink>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
